import request from '../utils/request'
import qs from 'qs'
// 用户登录注册
export const userLogin = data => {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
// 用户信息
export const userInfo = data => {
  return request({
    url: '/user/info',
    method: 'post',
    data
  })
}
// 发送短信
export const userSendCode = data => {
  return request({
    url: '/user/sendCode',
    method: 'post',
    data
  })
}
// 用户收藏
export const userCollect = data => {
  return request({
    url: '/user/collect',
    method: 'post',
    data
  })
}
// 更新用户信息
export const userUpdate = data => {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}
// 用户反馈
export const userFeedback = data => {
  return request({
    url: '/user/feedback',
    method: 'post',
    data
  })
}
// 拍卖行详情列表
export const auctionHouseInfoList = data => {
  return request({
    url: '/auction/auctionHouseInfoList',
    method: 'post',
    data
  })
}
// 拍卖会(推荐/精选/同步竞拍)列表
export const auctionList = data => {
  return request({
    url: '/auction/auctionList',
    method: 'post',
    data
  })
}

// 拍卖会拍品列表
export const auctionInfoList = data => {
  return request({
    url: '/auction/auctionInfoList',
    method: 'post',
    data
  })
}
// banner列表
export const banner = data => {
  return request({
    url: '/auction/banner',
    method: 'post',
    data
  })
}
// 分类列表
export const classify = data => {
  return request({
    url: '/auction/classify',
    method: 'post',
    data
  })
}
// 用户收藏列表
export const collectlist = data => {
  return request({
    url: '/user/collectlist',
    method: 'post',
    data
  })
}
// 拍品详情
export const auctionItemInfo = data => {
  return request({
    url: '/auction/itemInfo',
    method: 'post',
    data
  })
}
// 拍卖行列表
export const auctionHouseList = data => {
  return request({
    url: '/auction/auctionHouseList',
    method: 'post',
    data
  })
}
// Oss STS token
export const uploadFile = data => {
  return request({
    url: '/user/uploadFile',
    method: 'post',
    data
  })
}
// 近期浏览
export const recentBrowse = data => {
  return request({
    url: '/user/recentBrowse',
    method: 'post',
    data
  })
}
// 搜索
export const search = data => {
  return request({
    url: '/auction/search',
    method: 'post',
    data
  })
}
// 筛选属性列表
export const attribute = data => {
  return request({
    url: '/auction/attribute',
    method: 'post',
    data
  })
}
// 分类拍品列表
export const classifyItemList = data => {
  return request({
    url: '/auction/classifyItemList',
    method: 'post',
    data
  })
}
// 拍卖会属性筛选列表
export const auctionAttribute = data => {
  return request({
    url: '/auction/auctionAttribute',
    method: 'post',
    data
  })
}
// token
export const getToken = data => {
  return request({
    url: 'user/getToken',
    method: 'post',
    data
  })
}
// 拍卖会收藏
export const collectAuction = data => {
  return request({
    url: 'user/collectAuction',
    method: 'post',
    data
  })
}
// 获取出价列表
export const getOfferList = data => {
  return request({
    url: 'order/getOfferList',
    method: 'post',
    data
  })
}
// 立即出价
export const getPrice = data => {
  return request({
    url: 'auction/getPrice',
    method: 'post',
    data
  })
}
// 微信支付
export const weChatRecharge = data => {
  return request({
    url: 'pay/weChatRecharge',
    method: 'post',
    data
  })
}
// 支付宝支付
export const aliRecharge = data => {
  return request({
    url: 'pay/aliRecharge',
    method: 'post',
    data
  })
}
// 用户实名认证
export const realNameAuthentication = data => {
  return request({
    url: '/user/realNameAuthentication',
    method: 'post',
    data
  })
}
// 用户收货地址列表
export const addressList = data => {
  return request({
    url: '/user/addressList',
    method: 'post',
    data
  })
}
// 新增收货地址
export const addAddress = data => {
  return request({
    url: 'user/addAddress',
    method: 'post',
    data
  })
}
// 删除收货地址
export const delAddress = data => {
  return request({
    url: '/user/delAddress',
    method: 'post',
    data
  })
}
// 用户收货地址详情
export const addressInfo = data => {
  return request({
    url: '/user/addressInfo',
    method: 'post',
    data
  })
}
// 编辑收货地址
export const editAddress = data => {
  return request({
    url: '/user/editAddress',
    method: 'post',
    data
  })
}
// 用户设置默认收货地址
export const defaultAddress = data => {
  return request({
    url: 'user/defaultAddress',
    method: 'post',
    data
  })
}
// 获取竞价阶梯
export const auctionBidding = data => {
  return request({
    url: 'auction/auctionBidding',
    method: 'post',
    data
  })
}
// 微信订单状态查询
export const weChatRefund = data => {
  return request({
    url: 'pay/getOrderStatus',
    method: 'post',
    data
  })
}
// 我的账户明细
export const getAccountDetails = data => {
  return request({
    url: 'order/getAccountDetails',
    method: 'post',
    data
  })
}
// 获取竞拍记录
export const getAuctionRecord = data => {
  return request({
    url: 'order/getAuctionRecord',
    method: 'post',
    data
  })
}
// 获取订单列表
export const getOrderRecord = data => {
  return request({
    url: 'order/getOrderRecord',
    method: 'post',
    data
  })
}
// 拍卖会列表
export const collectAuctionList = data => {
  return request({
    url: '/user/collectAuctionList',
    method: 'post',
    data
  })
}
// 拍卖行列表
export const collectAuctionHouseList = data => {
  return request({
    url: '/user/collectAuctionHouseList',
    method: 'post',
    data
  })
}
// 取消竞拍订单
export const cancelOffer = data => {
  return request({
    url: 'order/cancelOffer',
    method: 'post',
    data
  })
}
// 开拍提醒
export const emindShooting = data => {
  return request({
    url: 'user/remindShooting',
    method: 'post',
    data
  })
}
// 获取立即支付信息
export const getImmediatePayment = data => {
  return request({
    url: 'order/getImmediatePayment',
    method: 'post',
    data
  })
}
// 余额支付
export const balance = data => {
  return request({
    url: 'pay/balance',
    method: 'post',
    data
  })
}
// 消息列表
export const unreadMessage = data => {
  return request({
    url: 'user/unreadMessage',
    method: 'post',
    data
  })
}
// 收藏取消拍卖行
export const collectAuctionHouse = data => {
  return request({
    url: 'user/collectAuctionHouse',
    method: 'post',
    data
  })
}
// 删除消息
export const delUnreadMessage = data => {
  return request({
    url: '/user/delUnreadMessage',
    method: 'post',
    data
  })
}
// 消息详情
export const unreadMessageInfo = data => {
  return request({
    url: 'user/unreadMessageInfo',
    method: 'post',
    data
  })
}
// 开拍提醒列表
export const userRemindShootingList = data => {
  return request({
    url: '/user/userRemindShootingList',
    method: 'post',
    data
  })
}
// 用户拍卖会下拍品分页
export const collectItemlist = data => {
  return request({
    url: '/user/collectItemlist',
    method: 'post',
    data
  })
}
// 地区选择
export const areaList = data => {
  return request({
    url: '/user/areaList',
    method: 'post',
    data
  })
}
// 提现
export const withdrawalOrder = data => {
  return request({
    url: 'pay/withdrawalOrder',
    method: 'post',
    data
  })
}
// 同步竞拍
export const SyncBiddingList = data => {
  return request({
    url: 'auction/SyncBiddingList',
    method: 'post',
    data
  })
}

// 修改收货地址
export const editOrderAddress = data => {
  return request({
    url: 'order/editOrderAddress',
    method: 'post',
    data
  })
}
// 翻译
export const alimt = data => {
  return request({
    url: 'alimt',
    method: 'post',
    data
  })
}
// 拍卖会详情筛选属性
export const auctionInfoAttribute = data => {
  return request({
    url: 'auction/auctionInfoAttribute',
    method: 'post',
    data
  })
}
// 分类筛下拉
export const classifyItemAttribute = data => {
  return request({
    url: '/auction/classifyItemAttribute',
    method: 'post',
    data
  })
}
export const searchAttribute = data => {
  return request({
    url: '/auction/searchAttrbute',
    method: 'post',
    data
  })
}
// 拍卖行登录
export const auctionHouseLogin = data => {
  return request({
    url: '/user/auctionHouseLogin',
    method: 'post',
    data
  })
}
// 拍卖行基本信息设置
export const setAuctionHouseEssentialInformation = data => {
  return request({
    url: '/live/setAuctionHouseEssentialInformation',
    method: 'post',
    data
  })
}
// 拍卖行注册
export const auctionHouseRegisterApi = data => {
  return request({
    url: '/user/auctionHouseRegister',
    method: 'post',
    data
  })
}
// 拍卖行地区列表
export const auctionAreaList = data => {
  return request({
    url: '/user/auctionAreaList',
    method: 'post',
    data
  })
}
// 获取拍卖行基本注册信息
export const getAuctionHouseRegisterDesc = data => {
  return request({
    url: '/user/getAuctionHouseRegisterDesc',
    method: 'post',
    data
  })
}
// 拍卖行账单信息设置
export const setAuctionHouseOrderInformation = data => {
  return request({
    url: '/live/setAuctionHouseOrderInformation',
    method: 'post',
    data
  })
}
// 拍卖行修改密码
export const setAuctionHousePasswordInformation = data => {
  return request({
    url: '/live/setAuctionHousePasswordInformation',
    method: 'post',
    data
  })
}
// 价格信息设置
export const setAuctionHousePriceInformation = data => {
  return request({
    url: '/live/setAuctionHousePriceInformation',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 找回密码
export const forgetPassword = data => {
  return request({
    url: '/live/forgetPassword',
    method: 'post',
    data
  })
}
// 获取邮箱验证码
export const sendEmailCode = data => {
  return request({
    url: '/live/sendEmailCode',
    method: 'post',
    data
  })
}
// 拍卖会列表
export const liveAuctionList = data => {
  return request({
    url: '/live/liveAuctionList',
    method: 'post',
    data
  })
}
// 获取时区列表
export const auctionTimeZoneList = data => {
  return request({
    url: '/live/auctionTimeZoneList',
    method: 'post',
    data
  })
}
// 汇率列表
export const exchangeRateList = data => {
  return request({
    url: '/auction/exchangeRateList',
    method: 'post',
    data
  })
}
// 添加拍卖会
export const addAuction = data => {
  return request({
    url: '/live/addAuction',
    data: qs.stringify(data),
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 上传excel
export const uploadItemData = data => {
  return request({
    url: '/live/uploadItemData',
    method: 'post',
    data
  })
}
// 上传zip
export const uploadItemImageData = data => {
  return request({
    url: '/live/uploadItemImageData',
    method: 'post',
    data
  })
}
// 提交审核
export const auctionAuditStatus = data => {
  return request({
    url: '/live/auctionAuditStatus',
    method: 'post',
    data
  })
}
// 拍卖会下拍品列表
export const liveAuctionItemList = data => {
  return request({
    url: '/live/liveAuctionItemList',
    method: 'post',
    data
  })
}
// 拍卖行下拍卖会信息
export const auctionInfo = data => {
  return request({
    url: '/live/auctionInfo',
    method: 'post',
    data
  })
}
// 删除拍卖会下拍品
export const delAuctionItem = data => {
  return request({
    url: '/live/delAuctionItem',
    method: 'post',
    data
  })
}
// 添加/编辑拍品
export const addAuctionItem = data => {
  return request({
    url: '/live/addAuctionItem',
    method: 'post',
    data
  })
}
// 更新拍卖会状态
export const updateAuctionStatus = data => {
  return request({
    url: '/live/updateAuctionStatus',
    method: 'post',
    data
  })
}
// 成交确认书列表
export const tradeInformationList = data => {
  return request({
    url: '/live/tradeInformationList',
    method: 'post',
    data
  })
}
// 成交确认书详情
export const tradeOrderInfo = data => {
  return request({
    url: '/live/tradeOrderInfo',
    method: 'post',
    data
  })
}
export const userTradeOrderInfo = data => {
  return request({
    url: '/live/userTradeOrderInfo',
    method: 'post',
    data
  })
}
// 发送成交确认书
export const sendTradeOrder = data => {
  return request({
    url: '/live/sendTradeOrder',
    method: 'post',
    data
  })
}
// 用户成交确认书
export const userTradeOrderInfoList = data => {
  return request({
    url: '/user/userTradeOrderInfoList',
    method: 'post',
    data
  })
}
// 创建成交确认书
export const createTradeOrderInfo = data => {
  return request({
    url: '/live/createTradeOrderInfo',
    method: 'post',
    data
  })
}
// 直播视频推流状态
export const isShowLive = data => {
  return request({
    url: '/live/isShowLive',
    method: 'post',
    data
  })
}
// 获取声网Token
export const getAgoraToken = data => {
  return request({
    url: '/live/getAgoraToken',
    method: 'post',
    data
  })
}
// zip包解压
export const liveUnzip = data => {
  return request({
    url: '/live/unZip',
    method: 'post',
    data
  })
}
// 获取直播拍品列表信息
export const getLiveAuctionItemList = data => {
  return request({
    url: '/live/getLiveAuctionItemList',
    method: 'post',
    data
  })
}

// 卖家直播登录
export const liveAuctionSellerCheck = data => {
  return request({
    url: '/live/liveAuctionSellerCheck',
    method: 'post',
    data
  })
}
// 添加品相报告
export const addPhaseReport = data => {
  return request({
    url: '/auction/addPhaseReport',
    method: 'post',
    data
  })
}
// 个人中心品相报告列表
export const getPhaseReportList = data => {
  return request({
    url: '/auction/getPhaseReportList',
    method: 'post',
    data
  })
}
// 品相报告
export const delPhaseReport = data => {
  return request({
    url: 'auction/delPhaseReport',
    method: 'post',
    data
  })
}
// 个人中心品相报告详情
export const getPhaseReportDetail = data => {
  return request({
    url: '/auction/getPhaseReportDetail',
    method: 'post',
    data
  })
}
// 结算明细
export const getBillingDetails = data => {
  return request({
    url: '/order/getBillingDetails',
    method: 'post',
    data
  })
}
// 正在直播拍卖会
export const liveAuctioningList = data => {
  return request({
    url: '/auction/liveAuctioningList',
    method: 'post',
    data
  })
}
//首页搜索
export const searchPrompt = data => {
  return request({
    url: '/auction/searchPrompt',
    method: 'post',
    data
  })
}
//商家申请入驻
export const applyEntryShop = data => {
  return request({
    url: '/mall/applyEntryShop',
    method: 'post',
    data
  })
}
//商家申请直播间
export const applyEntryLive = data => {
  return request({
    url: '/mall/applyEntryLive',
    method: 'post',
    data
  })
}
//获取经营品类
export const getSaleCategory = data => {
  return request({
    url: '/mall/getSaleCategory',
    method: 'post',
    params: data
  })
}
//获取商家申请记录
export const getMallApplyRecord = data => {
  return request({
    url: '/mall/getMallApplyRecord',
    method: 'post',
    data
  })
}
//商家入驻验证码
export const sendPhoneCode = data => {
  return request({
    url: '/mall/sendPhoneCode',
    method: 'post',
    params: data
  })
}
//商家找回密码
export const mallBusinessForgetPwd = data => {
  return request({
    url: '/mall/mallBusinessForgetPwd',
    method: 'post',
    data
  })
}
//商家登陆
export const mallBusinessLogin = data => {
  return request({
    url: '/mall/mallBusinessLogin',
    method: 'post',
    data
  })
}
//商城缴费支付
export const mallPay = data => {
  return request({
    url: 'pay/mallPay',
    method: 'post',
    data
  })
}
//商家申请详细信息
export const getMallApplyShopInfo = data => {
  return request({
    url: '/mall/getMallApplyShopInfo',
    method: 'post',
    data
  })
}
//获取直播申请详情
export const getMallApplyLiveInfo = data => {
  return request({
    url: '/mall/getMallApplyLiveInfo',
    method: 'post',
    data
  })
}
//查询支付成功
export const getMallOrderStatus = data => {
  return request({
    url: '/pay/getMallOrderStatus',
    method: 'post',
    data
  })
}
//拍品提醒
export const lotRemind = data => {
  return request({
    url: '/user/lotRemind',
    method: 'post',
    data
  })
}
//拍卖拍品提醒列表
export const userLotRemindList = data => {
  return request({
    url: '/user/userLotRemindList',
    method: 'post',
    data
  })
}
//拍品提醒列表
export const userLotRemindItemList = data => {
  return request({
    url: '/user/userLotRemindItemList',
    method: 'post',
    data
  })
}
//获取账户状态
export const accountStatus = data => {
  return request({
    url: '/user/accountStatus',
    method: 'post',
    data
  })
}
